import React from "react"
import {graphql} from "gatsby"
import id from "../lib/id";

import Layout from '../layouts/sidebar'
import Header from '../components/header'
import Button from '../components/button'
import styles from './single-partner/component.module.less'
import Text from '../components/text'
import Image from "../content-elements/image";

export default ({data, pageContext}) => {

    const locale = pageContext.locale || 'de';

    const config = data.allDatoCmsConfig.nodes[0];

    const partner = data.datoCmsVertriebspartner;

    const idLocaleReg = new RegExp(`-${locale}$`);
    const partnerContactId = partner.id
        .replace(/^DatoCmsVertriebspartner-/, '')
        .replace(idLocaleReg, '');

    const anchorItems = partner.inhalt
        .map(ce => ce.anchorName)
        .filter(a => a !== undefined && a.length > 0)
        .map((a, i) => {
            return {
                id: id(a),
                name: a,
                active: i === 0,
            }
        });

    function Subheadline() {
        return (
            <Text
                header={true}
                dark={true}
                title={partner.subheadline}
                headline={partner.name}
                headlineType="h1"
                className={styles.titles}
            />
        );
    }

    function Details() {
        return (
            <div className={styles.details}>
                {partner.telefon &&
                    <a href={"tel:" + partner.telefon} className={styles.telephone}>{partner.telefon}</a>
                }
                <a href={"mailto:" + partner.eMail} className={styles.mail}>{partner.eMail}</a>
                {partner.linkedin &&
                    <a
                        className={styles.linkedin}
                        target="_blank"
                        rel="noreferrer"
                        href={partner.linkedin}
                    >LinkedIn</a>
                }{partner.xing &&
                    <a
                        className={styles.xing}
                        target="_blank"
                        rel="noreferrer"
                        href={partner.xing}
                    >Xing</a>
                }
                {partner.facebook &&
                    <a
                        className={styles.facebook}
                        target="_blank"
                        rel="noreferrer"
                        href={partner.facebook}
                    >Facebook</a>
                }
            </div>
        );
    }

    function ContactButton() {
        return (
            <Button
                href={'/' + locale + '/' + config.pageContact.slug + '/#p=' + partnerContactId}
                theme="block"
                className={styles.contact}
            >
                {pageContext.templateStrings.vpPageSidebarContactButtonLabel}
            </Button>
        )
    }

    return (
        <Layout
            minimal={true}
            hideStickyNote={true}
            className={styles.partnerPage}
            locale={locale}
            localeLinks={pageContext.localeLinks}
            seo={pageContext.seoMetaTags}
            robots="noindex,nofollow"
            anchorItems={anchorItems}
            config={config}
            contentElements={partner.inhalt}
            sidebarTocLabel={pageContext.templateStrings.vpPageSidebarTocLabel}
            sidebarContactLabel={pageContext.templateStrings.vpPageSidebarContactLabel}
            sidebarContactButtonLabel={pageContext.templateStrings.vpPageSidebarContactButtonLabel}
            sidebarContact={partner}
            sidebarContactLink={'/' + locale + '/' + config.pageContact.slug + '/#p=' + partnerContactId}
        >
            {{
                header: (
                    <Header
                        sticky={false}
                        locale={locale}
                        localeLinks={pageContext.localeLinks}
                        minimal={true}
                    />
                ),
                intro: (
                    <>
                        <div className={partner.image ? styles.partner_pic : styles.partner_no_pic}>
                            {partner.image &&
                                <>
                                    <Image
                                        className={styles.left}
                                        image={partner.image}
                                        alt={partner.image.alt || partner.name}
                                        loading="eager"
                                    />
                                    <div className={styles.right}>
                                        <Subheadline/>
                                        {partner.slogan &&
                                            <div className={styles.slogan} dangerouslySetInnerHTML={{__html: partner.slogan}}/>
                                        }
                                        <Details/>
                                        <ContactButton/>
                                    </div>
                                </>
                            }
                            {!partner.image &&
                                <>
                                    <div className={styles.left}>
                                        <Subheadline/>
                                        <ContactButton/>
                                    </div>
                                    <div className={styles.right}>
                                        {partner.slogan &&
                                            <div className={styles.slogan} dangerouslySetInnerHTML={{__html: partner.slogan}}/>
                                        }
                                        <Details/>
                                        <Button
                                            href={'/' + locale + '/' + config.pageContact.slug + '/#p=' + partnerContactId}
                                            theme="block"
                                            className={styles.mobile_only}
                                        >
                                            {pageContext.templateStrings.vpPageSidebarContactButtonLabel}
                                        </Button>
                                    </div>
                                </>
                            }
                        </div>
                    </>
                )
            }}
        </Layout>
    );
}


export const query = graphql`
    query($locale: String!, $id: String!) {
  
        allDatoCmsConfig(filter: {locale: {eq: $locale}}) {
            nodes {
                ...config_data
            }
        }
  
        datoCmsVertriebspartner(id: {eq: $id }) {
            id
            subheadline
            name
            image {
                alt
                gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                    imgixParams: {w: "630", auto: "compress", fit: "crop", crop: "faces", dpr: 1.5, q: 75}
                )
                # used as background for sidebar avatar 
                fluid(imgixParams: {w: "60", h: "60", auto: "compress", fit: "crop", crop: "faces", dpr: 1.5, q: 75}) {
                    src
                }
            }
            eMail
            slogan
            telefon
            linkedin
            xing
            facebook
            seoMetaTags {
                tags
            }
            ...partner_content
        }
    }
`
